







































import { ApiResponse } from '@/interfaces'
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'MassImageUpload',
  props: {
    triggerUpload: {
      type: Boolean,
      required: true,
      default: false,
    },
    sale: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      uploadCompleted: 0,
      uploadStatus: null as string | null,
      showProgressModal: false,
      massSending: false,
      sendReport: [],
      diskQuota: 0,
      diskUsage: 0,
      axiosController: new AbortController(),
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.diskQuota = newVal.client.diskQuota
      this.diskUsage = newVal.client.diskUsage
    },
    triggerUpload() {
      if (!this.massSending) {
        document.getElementById('massUploadInput')?.click()
      }
    },
  },
  mounted() {
    if (this.user) {
      this.diskQuota = this.user.client.diskQuota
      this.diskUsage = this.user.client.diskUsage
    }
  },
  methods: {
    abortMassUpload() {
      if (this.massSending === true) {
        this.axiosController.abort()
        const input = document.getElementById('massUploadInput') as HTMLInputElement | null

        if (input) {
          input.value = ''
        }

        this.showProgressModal = false
      }
    },
    startMassUpload(event: Event) {
      const input = event.target as HTMLInputElement

      if (input.files && input.files.length) {
        // Checking if upload would exceed
        const fileSize = input.files[0].size

        // Multiply diskUsage and diskQuota by 1000 to get the value in Octets
        if (this.diskUsage * 1000 + fileSize > this.diskQuota * 1000) {
          this.$notify({
            title: 'Erreur',
            message: "Le fichier que vous essayez d'envoyer dépasse la limite du quota autorisée.",
            type: 'error',
          })
          this.abortMassUpload()
          return
        }

        // Upload the zip file
        this.uploadCompleted = 0
        this.uploadStatus = null
        this.showProgressModal = true
        this.massSending = true
        this.sendReport = []

        let formData = new FormData()

        formData.append('file', input.files[0])

        if (this.sale) {
          formData.append('sale', this.sale)
        }

        this.axiosController = new AbortController()

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            // Refusing the upload if it's higher than the limit specified in config
            const uploadLimit = parseInt(process.env.VUE_APP_UPLOAD_LIMIT ?? '')
            if (!isNaN(uploadLimit) && progressEvent.total > uploadLimit * 1000000) {
              this.$notify({
                title: 'Erreur',
                message: "Le taille du fichier que vous essayez d'envoyer est trop élevée.",
                type: 'error',
              })
              this.abortMassUpload()
            } else {
              this.uploadCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          },
          signal: this.axiosController.signal,
        }

        this.$api
          .post(`/${this.sale ? 'sale' : 'stock'}/zipupload`, formData, config)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.uploadStatus = 'success'
            this.sendReport = apiResponse.data.message

            // Emiting event that should trigger a list refresh
            this.$emit('updateItems')
          })
          .catch(() => {
            this.uploadStatus = 'exception'
            this.abortMassUpload()
          })
          .finally(() => {
            input.value = ''
            setTimeout(() => {
              this.massSending = false
            }, 1000)
          })
      }
    },
  },
})
