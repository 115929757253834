
















































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import EditItemsForm from '@/components/EditRequisitionItemsForm.vue'
import StockItems from '@/views/stocks/StockItems.vue'
import ItemFilters from '@/components/ItemFilters.vue'
import MassImageUpload from '@/components/MassImageUpload.vue'
import { mapState } from 'vuex'
import { ApiResponse, RequisitionItem, Pagination } from '@/interfaces'

interface DropdownAction {
  action: string
  item: RequisitionItem
  index?: number
  renumberBy?: string
}

export default Vue.extend({
  components: { EditItemsForm, Nav, StockItems, ItemFilters, MassImageUpload },
  data() {
    return {
      busy: false,
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      itemsChecked: 0,
      itemsToEdit: [] as number[],
      showEditModal: false,
      query: {},
      triggerMassUpload: false,
      refreshTrigger: false,
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
  },
  mounted() {
    if (this.$route.query) {
      this.query = this.$route.query
    }
  },
  methods: {
    changePage(page: string) {
      const query = {
        ...this.$route.query,
        page,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    setPaginationData(pagination: Pagination) {
      this.activePage = pagination.current
      this.itemsTotal = pagination.totalCount
      this.numPages = pagination.pageCount
    },
    handleItemCommand(command: DropdownAction) {
      // Mass edit
      if (command.action == 'massEdit') {
        this.openEditModal()
      }
      if (command.action == 'edit' && command.item) {
        this.editRequisitionItem(command.item)
      }
      if (command.action == 'massUpload') {
        this.triggerMassUpload = !this.triggerMassUpload
      }
      // export items
      if (command.action == 'export') {
        this.exportItems()
      }
      // export items
      if (command.action == 'statistics') {
        this.exportStatistics()
      }
    },
    editRequisitionItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
        query: {
          backTo: this.$route.name,
          backScrollTo: window.scrollY.toString(),
        },
      })
    },
    openEditModal() {
      this.showEditModal = true
    },
    closeEditModal() {
      this.showEditModal = false
      this.itemsChecked = 0
      this.itemsToEdit = []
    },
    updateItems(items: RequisitionItem[]) {
      ;(this.$refs.stockItems as InstanceType<typeof StockItems>).updateItems(items)
    },
    uncheckAll() {
      ;(this.$refs.stockItems as InstanceType<typeof StockItems>).toggleCheckAll(false)
    },
    setItemsChecked(itemsChecked: number) {
      this.itemsChecked = itemsChecked
    },
    setSelectedItems(itemsToEdit: number[]) {
      this.itemsToEdit = itemsToEdit
    },
    refreshList() {
      this.refreshTrigger = !this.refreshTrigger
    },
    exportItems() {
      // Get all Stocks with this filter
      this.busy = true
      this.$api
        .get('/stocks/export', {
          params: {
            search: this.$route.query.search,
            entry: this.$route.query.entryDate,
            itemNumber: this.$route.query.itemNumber,
            stockId: this.$route.query.stockId,
            sale: this.$route.query.sale,
            rubriques: this.$route.query.rubriques,
            stockStatuses: this.$route.query.stockStatuses,
            structure: this.$route.query.structure,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.success === true) {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
            window.open(documentUrl, '_blank')
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    exportStatistics() {
      // Get dat for statictics
      this.busy = true
      this.$api
        .get('/stocks/statistics')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.success === true) {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
            window.open(documentUrl, '_blank')
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
})
